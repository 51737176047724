<template>
  <div>
    <v-card class="pa-5">
      <div class="float-right">
        <v-btn
          color="error"
          :loading="backupLoading"
          @click="backup"
        >
          Backup Data
        </v-btn>
      </div>
      <v-card-title>
        <h4>Admin Dashboard</h4>
      </v-card-title>
      <v-card-text>
        <v-item-group>
          <v-item>
            <router-link to="/fkadmin/members">
              Members
            </router-link>
          </v-item>
          <br><br>
          <v-item>
            <router-link to="/fkadmin/nfts">
              NFT Admin
            </router-link>
          </v-item>
          <br><br>
          <v-item>
            <router-link to="/fkadmin/picks">
              Pickems
            </router-link>
          </v-item>
          <br><br>
          <v-item>
            <router-link to="/fkadmin/prizeClaims">
              Prize Claims
            </router-link>
          </v-item>
          <br><br>
          <v-item>
            <router-link to="/fkadmin/managers">
              Misc Admin
            </router-link>
          </v-item>
          <br><br>
        </v-item-group>
        <p class="float-right">
          Fantasy Kombat Version: {{ fkversion }}
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import firebase from 'firebase/app'
import 'firebase/functions'

export default {
  data() {
    return {
      fkversion: this.$version,
      backupLoading: false,
    }
  },
  methods: {
    async backup() {
      console.log('start backup')
      this.backupLoading = true
      const backup = firebase.functions().httpsCallable('manualBackup')
      await backup().then(data => {
        console.log(data)
        this.backupLoading = false
      }).catch(err => {
        console.log(err)
        alert('Backup failed!')
        this.backupLoading = false
      })
    },
  },
}
</script>
